import * as Yup from "yup";
import { SelectField, SwitchField, TextField } from "../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    serialNumber: Yup.string().required("serialNumber is required"),
    imei: Yup.string().required("imei is required"),
  });
}

export function formFields(isEdit) {
  const modelOptions = [
    {
      value: "TFT100",
      text: "TFT100",
    },
    {
      value: "FMM130",
      text: "FMM130",
    },
    {
      value: "MCX2M1",
      text: "MCX2M1",
    },{
      value: "FMC225",
      text: "FMC225",
    },
  ];
  const protocolOptions = [
    {
      value: "generic",
      text: "generic",
    },
    {
      value: "blitz",
      text: "blitz",
    },
  ];
  return [
    {
      size: 12,
      field: <TextField id="serialNumber" type="text" label="serialNumber" required />,
    },
    {
      size: 12,
      field: <TextField id="imei" type="text" label="imei" required disabled={isEdit} />,
    },
    {
      size: 12,
      field: <SelectField id="model" type="text" label="model" options={modelOptions} />,
    },
    {
      size: 12,
      field: <SelectField id="protocol" type="text" label="protocol" options={protocolOptions} />,
    },
    {
      size: 12,
      field: <SwitchField id="debugMode" type="text" label="debugMode" />,
    },
  ];
}

// private String serialNumber;
// private String imei;
// private String model;
// private String protocol;
// private boolean debugMode;

// -- auto filled
// private String ip;
// private Date date;
// private String carclubCode;
