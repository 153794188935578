import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Typography, withStyles } from "@material-ui/core";
import classnames from "classnames";
import WorkOrderConfigs from "./WorkOrder/WorkOrderConfigsWrapper";
import AppConfigs from "./App/AppConfigsWrapper";
import Aws from "./Aws/AwsWrapper";
import Registration from "./Registration/Registration";
import Button from "../../UI/Buttons/CommonButtons";
import screens from "../../../model/Carclub/configurationsScreens";
import styles from "./Configurations.styles";
import withPermission from "../../../hoc/withPermission";
import ExternalApi from "../../../features/carclub/CarclubConfigurations/ExternalApi/ExternalApi";
import Payments from "../../../features/carclub/CarclubConfigurations/Payments/Payments";
import CarclubConfigurations from "../../../features/carclub/CarclubConfigurations/CarclubConfigurations";
import LegalDocuments from "../../../features/carclub/CarclubConfigurations/LegalDocuments/LegalDocuments";
import { selectIsLoading } from "../../../features/carclub/carclubSlice";
import General from "../../../features/carclub/CarclubConfigurations/General/General";
import Trips from "../../../features/carclub/CarclubConfigurations/Trips/Trips";
import Devices from "../../../features/carclub/CarclubConfigurations/Devices/Devices";

const Configurations = ({
  classes,
  currentConfiguration,
  setCurrentConfiguration,
  onChangeHandler,
  placeholder,
  editMode,
  setEditMode,
  inputBuilders,
  updateConfigs,
  refreshConfigs,
  upsertTemplateHandlerCB,
  clearTemplateHandlerCB,
  isDefaultConfigs,
  defaultLocation,
}) => {
  const loading = useSelector(selectIsLoading);
  const externalApiSubmitRef = useRef();

  const tabsMapper = Object.values(screens).map((screen) => {
    const disable =
      (!!editMode &&
        (screen === screens.WORK_ORDERS || screen === screens.LEGAL) &&
        (currentConfiguration === screens.GENERAL ||
          currentConfiguration === screens.PAYMENTS ||
          currentConfiguration === screens.REGISTRATION ||
          currentConfiguration === screens.TRIPS)) ||
      (!!editMode &&
        (screen === screens.GENERAL ||
          screen === screens.PAYMENTS ||
          screen === screens.REGISTRATION ||
          screen === screens.WORK_ORDERS ||
          screen === screens.TRIPS) &&
        currentConfiguration === screens.LEGAL) ||
      (!!editMode &&
        (screen === screens.GENERAL ||
          screen === screens.PAYMENTS ||
          screen === screens.REGISTRATION ||
          screen === screens.LEGAL ||
          screen === screens.TRIPS) &&
        currentConfiguration === screens.WORK_ORDERS);

    if (
      isDefaultConfigs &&
      (screen === screens.WORK_ORDERS || screen === screens.LEGAL || screen === screens.EXTERNAL_API)
    ) {
      return null;
    }
    if (!isDefaultConfigs && (screen === screens.APP || screen === screens.AWS || screen === screens.DEVICES)) {
      return null;
    }
    return (
      <Typography
        className={classnames(
          classes.tab,
          screen === currentConfiguration && classes.selectedTab,
          disable && classes.disabled
        )}
        key={screen}
        onClick={() => !disable && setCurrentConfiguration(screen)}
      >
        {screen}
      </Typography>
    );
  });

  const configurations = {
    [screens.GENERAL]: (
      <General disabled={!editMode} submitRef={externalApiSubmitRef} isDefaultConfigs={isDefaultConfigs} />
    ),
    [screens.LEGAL]: <LegalDocuments />,
    [screens.TRIPS]: (
      <Trips disabled={!editMode} submitRef={externalApiSubmitRef} isDefaultConfigs={isDefaultConfigs} />
    ),
    [screens.PAYMENTS]: (
      <Payments disabled={!editMode} submitRef={externalApiSubmitRef} isDefaultConfigs={isDefaultConfigs} />
    ),
    [screens.REGISTRATION]: (
      <Registration
        onChangeHandler={onChangeHandler}
        placeholder={placeholder}
        editMode={editMode}
        inputBuilders={inputBuilders}
      />
    ),
    [screens.WORK_ORDERS]: (
      <WorkOrderConfigs
        upsertTemplateHandlerCB={upsertTemplateHandlerCB}
        clearTemplateHandlerCB={clearTemplateHandlerCB}
        inputBuilders={inputBuilders}
        masterEditMode={editMode}
        setMasterEditMode={setEditMode}
      />
    ),
    [screens.APP]: (
      <AppConfigs
        onChangeHandler={onChangeHandler}
        placeholder={placeholder}
        editMode={editMode}
        inputBuilders={inputBuilders}
        isDefaultConfigs={isDefaultConfigs}
      />
    ),
    [screens.AWS]: (
      <Aws
        onChangeHandler={onChangeHandler}
        placeholder={placeholder}
        editMode={editMode}
        inputBuilders={inputBuilders}
        isDefaultConfigs={isDefaultConfigs}
      />
    ),
    [screens.EXTERNAL_API]: <ExternalApi disabled={!editMode} submitRef={externalApiSubmitRef} />,
    [screens.DEVICES]: <Devices disabled={!editMode} submitRef={externalApiSubmitRef} />,
  };

  const configuration = configurations[currentConfiguration];

  return (
    <div className={classes.root}>
      <CarclubConfigurations isDefaultConfigs={isDefaultConfigs} />
      <div className={classes.titleBar}>
        <Typography className={classes.title}>
          {isDefaultConfigs ? "Default" : defaultLocation.name} Configurations
        </Typography>
        {!editMode && currentConfiguration !== screens.WORK_ORDERS && currentConfiguration !== screens.LEGAL && (
          <ButtonWithPermission>
            <Button
              buttonStyleName="filled"
              className={classes.button}
              key="edit_button"
              label="Edit"
              disabled={loading}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </ButtonWithPermission>
        )}
        {editMode && currentConfiguration !== screens.WORK_ORDERS && currentConfiguration !== screens.LEGAL && (
          <ButtonWithPermission>
            <Button
              buttonStyleName="void"
              className={classes.button}
              key="cancel_button"
              label="Cancel"
              onClick={() => {
                setEditMode(false);
                refreshConfigs();
              }}
            />
          </ButtonWithPermission>
        )}
        {editMode && currentConfiguration !== screens.WORK_ORDERS && currentConfiguration !== screens.LEGAL && (
          <ButtonWithPermission>
            <Button
              buttonStyleName="filled"
              className={classes.button}
              key="save_button"
              label="Save"
              disabled={loading}
              onClick={() => {
                if (
                  currentConfiguration === screens.EXTERNAL_API ||
                  currentConfiguration === screens.PAYMENTS ||
                  currentConfiguration === screens.GENERAL ||
                  currentConfiguration === screens.TRIPS ||
                  currentConfiguration === screens.DEVICES
                ) {
                  externalApiSubmitRef?.current && externalApiSubmitRef.current.click();
                  setTimeout(() => {
                    setEditMode(false);
                  }, 200);
                } else {
                  updateConfigs();
                }
              }}
            />
          </ButtonWithPermission>
        )}
        {!editMode && currentConfiguration === screens.WORK_ORDERS && (
          <ButtonWithPermission>
            <Button
              buttonStyleName="filled"
              className={classes.button}
              key="edit_template_button"
              label="Edit"
              onClick={() => {
                setEditMode(true);
              }}
            />
          </ButtonWithPermission>
        )}
        {editMode && currentConfiguration === screens.WORK_ORDERS && (
          <ButtonWithPermission>
            <Button
              buttonStyleName="filled"
              className={classes.button}
              key="save_template_button"
              label="Save"
              onClick={() => {
                upsertTemplateHandlerCB.current();
                setEditMode(false);
              }}
            />
          </ButtonWithPermission>
        )}
        {editMode && currentConfiguration === screens.WORK_ORDERS && (
          <ButtonWithPermission>
            <Button
              buttonStyleName="void"
              className={classes.button}
              key="cancel_template_button"
              label="Cancel"
              onClick={() => {
                clearTemplateHandlerCB.current();
                setEditMode(false);
              }}
            />
          </ButtonWithPermission>
        )}
      </div>
      <div className={classes.tabs}>{tabsMapper}</div>
      {configuration}
    </div>
  );
};

const ButtonWithPermission = withPermission([{ resource: ["carclub", "configurations", "edit"] }])(({ children }) => (
  <>{children}</>
));

export default withStyles(styles)(Configurations);
