import { createSlice } from "@reduxjs/toolkit";
import { guid, sleep } from "../common/utils";
import { call } from "./websocket/_sliceCalls/websocket";

export const coreSlice = createSlice({
  name: "core",
  initialState: {
    loader: [],
    snacks: [],
  },
  reducers: {
    addToLoader: (state, action) => {
      state.loader = [...state.loader, action.payload];
    },
    removeFromLoader: (state, action) => {
      let nLoader = state.loader;
      state.loader = nLoader.filter((item) => item.call !== action.payload?.call && item.date !== action.payload?.date);
    },
    addSnack: (state, action) => {
      state.snacks = [...state.snacks, action.payload];
    },
    removeSnack: (state, action) => {
      state.snacks = [...state.snacks.filter((item) => item.id !== action.payload)];
    },
  },
});

export default coreSlice.reducer;
export const { addSnack, removeSnack, removeFromLoader, addToLoader } = coreSlice.actions;

// SELECTS

export const selectSnacks = (state) => state.getIn(["core"])?.snacks;
export const selectIsLoading = (state) => state.getIn(["core"])?.loader?.length > 0;
export const selectIsCallsLoading = (calls) => (state) =>
  Boolean((state.getIn(["core"])?.loader || []).find((item) => calls.indexOf(item.call) > -1));

// CALLS

export const wsCall = async ({ type, subType, message, noWsid, locationId }) => {
  console.log("%c::: %s/%s ::: %o", "color:#43bb98; font-weight:bold", type, subType, message || {});
  try {

    let processMessage;
    let error;
    try {
      processMessage = await call({
        wsid: guid(),
        type,
        subType,
        message,
        locationId,
      });
    } catch (ex) {
      error = ex;
    }

    if (error) {
      console.log("%c::: ERROR ::: %o", "color:#ff9a38; font-weight:bold", error);
      return { ...error, error: true };
    }
    console.log("%c[RESP] %s/%s %o", "color:#00aa66; font-weight:bold", type, subType, processMessage);
    return processMessage;
  } catch (_ex) {
    return null;
  }
};

export const setError = (msg) => async (dispatch) => {
  if (msg) {
    let snack = {
      id: guid(),
      type: "error",
      msg,
    };
    dispatch(addSnack(snack));
    await sleep(8000);
    dispatch(removeSnack(snack.id));
  }
};

export const setSuccess = (msg) => async (dispatch) => {
  if (msg) {
    let snack = {
      id: guid(),
      type: "success",
      msg,
    };
    dispatch(addSnack(snack));
    await sleep(8000);
    dispatch(removeSnack(snack.id));
  }
};

export const requestHelper = (dispatch, loader) => {
  //dispatch(cleanSnack());
  let timestamp = new Date().getTime();
  if (loader) {
    dispatch(addToLoader({ call: loader, date: timestamp }));
  }
  return {
    error: (error) => {
      dispatch(setError(error));
    }, //dispatch(setError(ex.tag)),
    close: () => {
      if (loader) {
        dispatch(removeFromLoader({ call: loader, date: timestamp }));
      }
    },
  };
};

export const s3GetPublic = async ({ s3Link }) => {
  return await wsCall({
    type: "S3",
    subType: "GET_PUBLIC",
    message: {
      s3Link,
    },
  });
};

export const publishAndAwait = async (request) => {
  console.log("publishAndAwait %s/%s", request?.body?.type, request?.body?.subType);
  let result = await wsCall(request.body);
  return {
    response: {
      processMessage: result,
      error: result.error,
    },
  };
};
