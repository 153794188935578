import React from "react";
import ActivityLogItem from "./ActivityLogItem/ActivityLogItem";
import { List, withStyles } from "@material-ui/core";
import { styles } from "./ActivityLogList.styles";
import classnames from "classnames";

const ActivityLogList = ({ classes, className, disablePadding, activityLogs, timelineLogs, timelineLogClassName }) => {
  const sortedActivityLogs =
    !!activityLogs && activityLogs.sort((a, b) => new Date(b.comms.create_date) - new Date(a.comms.create_date));

  const sortedTimelineLogs = !!timelineLogs && timelineLogs.sort((a, b) => new Date(b.date) - new Date(a.date));

  console.log("sortedActivityLogs: %o", sortedActivityLogs);

  const styledActivityLogs =
    !!sortedActivityLogs &&
    sortedActivityLogs.map((log, index) => {
      return (
        <ActivityLogItem
          key={"activity-log-" + index}
          activityLog={log}
          lastLog={index + 1 === sortedActivityLogs.length}
        />
      );
    });

  //TODO pass timeline log to activity log item or creare timelineLogiItem
  const styledTimelineLogs =
    !!sortedTimelineLogs &&
    sortedTimelineLogs.map((log, index) => {
      return (
        <ActivityLogItem
          key={"timeline-log-" + index}
          activityLog={log}
          lastLog={index + 1 === sortedTimelineLogs.length}
          timelineLogClassName={timelineLogClassName}
        />
      );
    });

  return (
    <List
      key={"list-" + !!sortedTimelineLogs ? "timeline" : "activityLog"}
      className={classnames(classes.root, className)}
      disablePadding={disablePadding}
    >
      {styledActivityLogs}
      {styledTimelineLogs}
    </List>
  );
};

export default withStyles(styles)(ActivityLogList);
