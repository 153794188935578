import React from "react";
import { Modal, withStyles } from "@material-ui/core";
import Filter from "./Filter/Filter";
import Lists from "./Lists/Lists";
import Details from "./Details/Details";
import styles from "./TripAnalysis.styles";

const TripAnalysis = ({
  classes,
  data,
  filter,
  onChange,
  onClose,
  onRemove,
  onSearch,
  open,
  searchTypes,
  selected,
  setSelected,
}) => {
  console.log("data: %o", data);
  const filters = <Filter filter={filter} onChange={onChange} onSearch={onSearch} types={searchTypes} />;

  const lists = <Lists data={data} onRemove={onRemove} selected={selected} setSelected={setSelected} />;

  const details = selected && <Details selected={selected} />;

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.data}>
          {filters}
          {lists}
        </div>
        {details}
      </div>
    </Modal>
  );
};

export default withStyles(styles)(TripAnalysis);
