import * as Yup from "yup";
import { TextField, SwitchField } from "../../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    geotab: Yup.object().shape({
      server: Yup.string().when("enable", {
        is: true,
        then: (schema) => schema.required("Server is required"),
        otherwise: (schema) => schema,
      }),
      database: Yup.string().when("enable", {
        is: true,
        then: (schema) => schema.required("Database is required"),
        otherwise: (schema) => schema,
      }),
      username: Yup.string().when("enable", {
        is: true,
        then: (schema) => schema.required("Username is required"),
        otherwise: (schema) => schema,
      }),
      password: Yup.string().when("enable", {
        is: true,
        then: (schema) => schema.required("password is required"),
        otherwise: (schema) => schema,
      }),
    })
  });
}

export function formFields(disabled, enable) {

  return [
    {
      size: 12,
      field: <SwitchField id="geotab.enable" label="Active" disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="geotab.server" type="text" label="Server" disabled={disabled || !enable} />,
    },
    {
      size: 12,
      field: <TextField id="geotab.database" type="text" label="Database" disabled={disabled || !enable} />,
    },
    {
      size: 12,
      field: <TextField id="geotab.username" type="text" label="Username" disabled={disabled || !enable} />,
    },
    {
      size: 12,
      field: <TextField id="geotab.password" type="text" label="Password" disabled={disabled || !enable} />,
    },
  ];
}

// "devices": {
//   "geotab": {
//     "enable": false,
//     "server": "my.geotab.com",
//     "database": "",
//     "username": "",
//     "password": ""
//   }
// }
