import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";

const useDateTime = () => {
  const selectedLocation = useSelector(selectDefaultLocation);
  const timezoneDefault = useSelector((state) => state.getIn(["carclub", "timezone"]));

  const timezoneRef = useRef();
  useEffect(() => {
    timezoneRef.current = selectedLocation?.timezone || timezoneDefault;
  });

  const timePatterns = null; //useSelector((state) => state.getIn(["carclub", "patterns", "time"]).toJS());
  // FUTURE: allow this to changed as configuration, for now it uses the default ones

  const getTimezone = () => {
    return timezoneRef?.current || selectedLocation?.timezone || timezoneDefault;
  };

  const typeFormat = (mDate, type) => {
    if (!moment.isMoment(mDate)) {
      return "";
    }
    switch (type) {
      case "date":
        return mDate.format(timePatterns?.date || "DD-MM-YYYY");
      case "dateTime":
        return mDate.format(timePatterns?.dateTime || "DD-MM-YYYY HH:mm");
      case "dateTimeComplete":
        return mDate.format(timePatterns?.dateTime || "DD-MM-YYYY HH:mm:ss");
      case "hourMinutes":
        return mDate.format(timePatterns?.hourMinutes || "HH:mm");
      case "hourMinutesSeconds":
        return mDate.format(timePatterns?.hourMinutesSeconds || "HH:mm:ss");
      default:
        return mDate;
    }
  };

  return {
    now: (type) => {
      return typeFormat(moment.utc().tz(getTimezone()), type);
    },
    isPast: (date) => {
      let dateToCheck = moment.utc(date).tz(getTimezone());
      let now = moment.utc().tz(getTimezone());
      return dateToCheck.isBefore(now);
    },
    toTimeZone: (date, type) => {
      return typeFormat(moment.utc(date).tz(getTimezone()), type);
    },
    toUtc: (date) => {
      if (date) {
        let r = moment.tz(date, getTimezone()).utc();
        console.log("toUtc date: %o [%o] = %o", date, getTimezone(), r.format("YYYY-MM-DD HH:mm:ss"));

        return r;
      }
      return;
    },
    getAllTimeZones: () => {
      if (!moment?.tz) {
        return [];
      }
      const timeZonesList = moment.tz.names();
      return timeZonesList
        .map((tz) => {
          const offset = moment.tz(tz).utcOffset();
          return {
            offset,
            tz,
            designation:
              "(UTC" +
              (offset >= 0 ? "+" : "") +
              (offset / 60).toFixed(0) +
              ":" +
              (offset % 60 > 0 ? offset % 60 : "00") +
              ") " +
              tz,
          };
        })
        .sort((a, b) => (a.offset < b.offset ? -1 : 1));
    },
    getCurrentTimeZone: () => {
      if (!moment?.tz) {
        return null;
      }
      return moment.tz.guess();
    },
    getMinutesAgo: (date) => {
      return moment.utc().diff(moment.utc(date).tz(getTimezone()), "minutes");
    },
    getDaysAgo: (date) => {
      return moment.utc().diff(moment.utc(date).tz(getTimezone()), "days");
    },
  };
};

export default useDateTime;
